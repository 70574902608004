@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

.cbutton{
    width:50px;
    height:50px;
}

.frames{
    width:100%;
}
.frame{
    margin-left: auto;
    margin-right: auto;
    width: 45%;
}

.cicon{
    width: 100%;
    height:100%;
}

.cbutton{
    margin-top: 30px;
    &:hover{
        background: rgba(0, 0, 0, 0.75);
        cursor: pointer;
        .cicon{
            color:#1EBDF4;
        }
    }
    background: none;
    border: 0px;
    outline: none;
    transition: 0.4s;
}

.cbutton:focus{
    .cicon{
        color: red;
    }
    border-color: black;
    border: 1px solid;
}

.main{
    width:80%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}
