
.more-filters-container {
    position: relative;
    z-index: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1.5em;
    background-color: white;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
}

#size-img {
    width: 80%;
    height: auto;
}

.number {
    font-size: 20px;
    color: #020D2A;
    font-weight: bolder;
    position: absolute;
    z-index: 1;
    translate: -50%, -50%;
}

.selected {
    text-shadow: 0px 0px 8px #FF00F5;
    color: #af29aa;
}

.lots-of-buttons {
    display: flex;
    flex-flow: row wrap;
    margin: 0.25em;
    width: stretch;
    justify-content: center;
}

.more-filters-button {
    margin: 0.5em;
    background-color: #FF00F5;
    border: #FF00F5 2px solid;
    border-radius: 50px;
    padding: 0.75em;
    margin-top: 0.75em;
    min-height: max-content;
    font-weight: bolder;
    line-height: 0.5em;
    color: #041850;
    font-size: 18px;
    font-family: 'Josefin Sans', sans-serif;
}

.more-filters-button:hover {
    cursor: pointer;
    background-color: #FF00F5;
    color: white;
}

.more-filters-button:focus {
    outline: none;
}

.transparent {
    opacity: 0;
}

.transparent:hover {
    cursor: default;
}

.speech-bubble {
    background-color: #020D2A;
    border: 2px solid #FF00F5;
    border-radius: 15px;
    padding: 1em;
    margin-top: 0.5em;
    min-height: max-content;
    
}

.speech-bubble>p{
    margin: 0.25em;
    color: #FFEBFE;

}


@keyframes fadeIn {
    from{opacity: 0}
    to{opacity: 1}
}
