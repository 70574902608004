#container {
    z-index: auto;
    position: relative;
    overflow: hidden;
    background: black;
    /* filter: brightness(0%); */
    background-repeat: no-repeat;
    background-size: 100% auto; /*THIS IS STILL NOT WORKING */
    width: 100%;
    height: 50vw; /*estimated proportions because background image messes things up :( */
    max-height: 480px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid #FF00F5;
   
}
#instructions {
    color: #FFEBFE;
    font-weight: bolder;
    z-index: 2;
    filter: brightness(100%);
    text-align: center;
    background-color: #FF00F5;
    padding: 0.3em;
    margin: 0.25em;
    border-radius: 15px;
    cursor: default;
}
#mouse {
    height: 20px;
    width: auto;
    filter: invert(1);
    /*still need to center it cuz it looks ugly*/
}
#spotlight {
    opacity: 0; /*so that it is not shown until mouseover*/
    border-radius: 100%;
    content: "";
    position: absolute;
    width: 5vw;
    height: 5vw;
    z-index: 1;
    transform: translate(-50%, -50%); /*center on mouse*/
    border: solid  black 150vw;
}