/*TODO: Remove duplication in classes */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap');
body {
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  background-color: #020D2A;
}

h1 {
  font-size: 30px;
  margin: 0;
  color: #FFEBFE;
  font-family: 'Poppins', sans-serif;
  /* font-weight: 800; */
  /* border-bottom: solid #1EBDF4; */
}

.topheader{
  margin-top: 40px;
}

h2 {
  font-size: 20px;
  color: #FF00F5;
  line-height: 1.5;
  font-weight: 600;
}

p {
  font-size: 18px;
  line-height: 1.5;
  color: #FFEBFE;
  margin: 0.5em 0;
  /* text-align: justify; */
}

em {
  font-style: normal;
  font-weight: 700;
  color: #FF80FA;
}

.section {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0.5em 1em;
  /* BACKGROUND COLOR: */
  background-color: #020D2A;
}

/* .blue { /*blue divs
  background-color: #1EBDF4;
}

.grey { /*grey divs
  background-color: #EBF9FE;
} */
.banner {
  padding: 2em 1em;
  background-color: #1EBDF4;
  display: flex;
  justify-content: center;
  text-align: center;
}

.index{
  margin-left: 35%;
  display: flex;
  max-width: 30%;
  border: solid 2px #1EBDF4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner h1 {
  color: white;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  width:90%;
  max-width: 750px;
  padding: 1em;
  opacity: 0.95;
  /* box-shadow: 0px 0px 10px 2px rgba(30, 30, 30, 0.2); */
  justify-content: flex-start;

}.toc-panel {
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  border-radius: 7px;
  width: 95%;
  padding: 1em;
  opacity: 0.95;
}
.mini-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 7px;
  box-shadow: 0px 0px 10px 2px rgba(30, 30, 30, 0.2);
  padding: 1em;
  margin: 5%;
  width: 50%;
  min-width: 300px;
  background: white;
}

.mini-card ol {
  font-size: 20px;
  margin: 0.5em;
  padding-left: 1em;
}

.mini-card ol li {
  margin: 0.25em 0;
}

.clipart-container {
  display: flex;
  max-width: 500px;
  min-width: 300px;
  width:50%;
}
#clipart {
  border-radius: 10px;
  width: 100%;
}
@media screen and (max-width:690px) {
  .toc-panel { flex-wrap: wrap; }
  .mini-card { width: 100%;}
  .clipart-container { width: 100%;}
}
.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
.example-list {
  display: grid;
  grid-template-columns: 30% 30% 35%;
  column-gap: 2em;
  /* margin: 0 1em; */
  padding: 1vw;
}

@media screen and (max-width: 500px) {
  .example-list {
    display: grid;
    grid-template-columns: 100%;
    margin: 1em 1em;
  }
  .example {
    font-style: normal;
    font-weight: normal;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: #7ac8f5;
    padding: 0em 1em;
    margin: 1em 1em;
  }
}

.example {
  font-style: normal;
  font-weight: normal;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #7ac8f5;
  padding: 0em 1em;
}
#example-img {
  width: 90%;
  max-width: 60px;
  min-width: 40px;
  margin: 0 1em;
}
#example-img-soundwave {
  width: 100%;
  max-width: 100px;
  min-width: 60px;
  margin: 0 1em;
}
.bluelink {
  color: #7AC8F5;
}
.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1vw;
}

.dark-link {
  background: #041850;
  border: 2px solid #041850;
  border-radius: 39px;
  color: #FF00F5;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  padding: 0.5em 1em;
  margin: 0 1em;
}

.dark-link:hover{
  border: 2px solid #FF00F5;
}

.chubby-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: stretch;
  max-height: 140px;
  background-color: #1EBDF4;
  width: 90%;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 7px;
  color: #FFEBFE;
  text-decoration: none;
  font-size: 20px;
  font-weight: bolder;
  box-shadow: 0px 0px 8px 2px rgba(30, 30, 30, 0.2);
}

.navButton{
  color: #041850;
  text-decoration: none;
  font-size: larger;
  margin-bottom: 10px;
}

.navButton:hover{
  font-weight: 600;
}

.hamMenu{
  height: 10%;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #FF00F5;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #373a47;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
}

/* General sidebar styles */
.bm-menu {
  background: #FFEBFE;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list[style]{
  height: auto !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.chubby-link:hover {
  background-color: white;
  color:  #1EBDF4;
  border: solid #1EBDF4 3px;
}

.navigation{
  width: 30%;
  margin: auto;
  display: flex;
  justify-content: center;
  background-color: #020D2A;
  margin-bottom: 50px;
}

/* .bPage{
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
} */

#coverImage{
  vertical-align: middle;
  width: auto;
  max-width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

#startButton{
  background-color: #041850;
  color: #FF00F5;
  font-size: larger;
  font-weight: 600;
  border: 2px #FF00F5 solid;
  padding: 10px;
  float: left;
  position: absolute;
  left: 45%;
  top: 60%;
  z-index: 1000;
  border-radius: 25px;
  text-decoration: none;
}

#startButton:hover{
  background-color: #FF00F5;
  color: #041850;
  border: 2px #041850 solid;
}

#leftB{
  background-color: #041850;
  color: #FF00F5;
}

#leftB:hover{
  background-color: #FF00F5;
  color: #041850;
  border-right: 2px #041850 solid;
}

#rightB{
  background-color: #FF00F5;
  color: #041850;
}

#rightB:hover{
  background-color: #041850;
  color: #FF00F5;
  border: 2px #FF00F5 solid;

}

#leftB2{
  background-color: #041850;
  color: #FF00F5;
  padding-right: 8px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-right: 2px #041850 solid;
}

#leftB2:hover{
  background-color: #FF00F5;
  color: #041850;
}


#rightB2{
  background-color: #FF00F5;
  color: #041850;
  padding-left: 8px;
  border-bottom-right-radius: 30px;
  border-top-right-radius: 30px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 2px #FF00F5 solid;
}

#rightB2:hover{
  background-color: #041850;
  color: #FF00F5;
}

.navArrow{
  font-size: 20px;
  text-decoration: none;
  border-radius: 30px;
  padding: 13px;
}

.navArrow:hover{
  color: blue;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .grid-60-40 {
    display: flex;
    flex-direction: column;
  }
  .links {
    margin: 0;
    align-items: center;
  }
  .chubby-link {
    height: max-content;
  }
}

#flashlight-holder {
  /* display: grid;
  grid-template-columns: 30% 70%; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.grid-50-50 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.needs-padding {
  padding-right: 1em;
}


.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .grid-50-50 {
    display: flex;
    flex-direction: column;
  }
}

#koala-reveal-img {
  width: 90%;
  height: auto;
  border-radius: 7px;
}

.grid-holder {
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
}

.grid-img {
  width: 100%;
  height: auto;
  border-radius: 7px;
}

.side-text {
  margin-left: 2em;
}
.left-text {
  max-width: 50rem;
}

@media screen and (max-width: 600px) {
  .grid-holder {
    display: flex;
    flex-direction: column;
  }
  .side-text {
    margin: 0;
  }
}

.link-to-slides {
  /* border: 2px #ff8800 solid; */
  /* background-color: #ff8800;
  border-radius: 7px; */
  font-size: 18px;
  color: #7ac8f5;
  /* padding: 0.25em;
  font-weight: bolder; */
  text-decoration: none;
  /* max-width: max-content;
  align-self: center; */

  /* box-shadow: 0px 0px 8px 2px rgba(30, 30, 30, 0.1); */
}
.link-to-slides:hover {
  text-decoration: underline;
}

.filters-intro {
  justify-content: space-between;
}

.filterGIF-container {
  width:100%;
  display: flex;
  align-items: center;
}

#filterGIF {
  align-self: center;
  width: 250px;
  height: auto;
}
.filters-intro {
  justify-content: space-between;
}

.filters-intro div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sentiment-holder {
  align-items: flex-end;
  width: 250px;
}
#sentiment-analysis {
  width:100%;
  height: auto;
}

@media screen and (max-width: 800px) {
  .sentiment-holder {
    width: stretch;
  }
}

#fullscreen {
  font-family: 'Source Sans Pro', sans-serif;
  align-self: left;
  border: 2px #1EBDF4 solid;
  font-size: 16px;
  color: black;
  padding: 0.5em;
  background-color: #EBF9FE;
}
#fullscreen:hover {
  opacity: 0.7;
}
.cnnHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2%;
}

#cnn-vis {
  display: flex;
  flex-direction: column;
  background-color: #FFEBFE;
  max-width: 650px;
  max-height: 450px;
  align-self: center;
}

#conclusion {
  text-align: center;
  
}
#cnn-frame {
  width: 100%;
}

.main2{
    width:100%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}

.unordered {
  color: #FFEBFE;
  list-style-type: disc;
  font-size: 30px;
}