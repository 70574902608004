.filters-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0em 5.5em;
    max-width: 80%;
}

.filters-holder img {
    margin: 1em;
    max-width: 300px;
    height: auto;
}


#form {
    margin: 1em;
    width: 20vw;
    height: max-content;
    color:#FFEBFE;
}

#form>* {
    margin: 1em;
}

.kernel-matrix{
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    border: 0.5px rgba(255, 235, 254, 0.5) solid;
}

.kernel-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.kernel-row>* {
    border: 0.5px rgba(255, 235, 254, 0.5) solid;
    padding: 8px 0px;
    font-size: 16px;
    text-align: center;
}

#form .form-select {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

#form label {
    margin-right: 0.25em;
}

@media screen and (max-width: 800px) {
    .filters-holder {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .filters-holder img {
        max-width: 300px;
        height: auto;
    }
    #form {
        width: max-content;
        height: max-content;
    }

}

#img-description {
    /* max-width: 80%; */
    margin: 1em;
    display: inline-block;
}



.small-stuff {
    font-size: 14px;
}

.small-stuff a {
    color: #FF00F5;
    font-weight: bolder;
    text-decoration: none;
}

.small-stuff a:hover {
    text-decoration: underline;
}
